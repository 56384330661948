 /*dark mode background color*/
body {
    margin: 0;
    background-color: #fff;
}

h1, h2, h3, h4, h5, h6, p, span, ul, li {
    color: black;
}

